import React, { useState, useEffect } from 'react';
import { api } from '../../../lib/axios';
import { useQuery } from '@tanstack/react-query';
import { MenuItem } from '@mui/material';
import { useContracts } from '../../../hooks/use-contracts';
import { cn } from '../../../lib/utils';
import { createTaskTree, flattenTasks, updateAssignmentFinishDates } from '../../../utils/group-by';

import { BraskemHeader } from '../BraskemHeader';
import { MainCard } from './MainCard';

import { ViewerBraskem } from '../../../Components/PlugBase/Viewer/ViewerBraskem';

import { Select } from '../../../Components/Select';
import { BarLineChart } from '../../../Components/BarLineChart';
import { TaskTree } from '../../../Components/TaskTree';
import { Spinner } from '../../../Components/Spinner';

export default function FisicoBraskem() {
  const [contractState, setContractState] = useState({
    selectedContract: '',
    formattedContract: '',
    mobileOpen: false,
    openBranches: [],
    selectedTask: null,
    selectedType: 'avanco'
  });

  const { selectedContract, formattedContract, mobileOpen, openBranches, selectedTask, selectedType } = contractState;

  // Atualizar o estado
  const setSelectedContract = (contract) => setContractState(prevState => ({ ...prevState, selectedContract: contract }));
  const setFormattedContract = (contract) => setContractState(prevState => ({ ...prevState, formattedContract: contract }));
  const setMobileOpen = (isOpen) => setContractState(prevState => ({ ...prevState, mobileOpen: isOpen }));
  const setOpenBranches = (branches) => setContractState(prevState => ({ ...prevState, openBranches: branches }));
  const setSelectedTask = (task) => setContractState(prevState => ({ ...prevState, selectedTask: task }));
  const setSelectedType = (type) => setContractState(prevState => ({ ...prevState, selectedType: type }));

  const contractsQueryData = useContracts('braskem');

  // Alternar o estado de um ramo aberto
  function toggleBranch(taskId) {
    if (openBranches.includes(taskId)) {
      setOpenBranches(openBranches.filter((id) => id !== taskId));
    } else {
      setOpenBranches([...openBranches, taskId]);
    }
  }

  // Lidar com a seleção de um contrato
  function handleSelectContract(event) {
    const contract = event.target.value.split('/')[1];
    const formattedContract = contract.slice(0, contract.length - 4);

    setFormattedContract(formattedContract);
    setSelectedContract(event.target.value);
    setMobileOpen(false);
  }

  // Lidar com a seleção de uma tarefa
  function handleSelectTask(task) {
    if (selectedTask && selectedTask.TaskId === task.TaskId) {
      setSelectedTask(null);
    } else {
      // setSelectedTask(task);
      setSelectedTask(null);
    }
  }

  // Buscar os dados de contrato
  const fetchContractData = async (formattedContract) => {
    try {
      const response = await api.get(`/braskem/main-info?numContrato=${formattedContract}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch contract data');
    }
  };
  const mainInfoQuery = useQuery({
    queryKey: [`braskem/main-info/${formattedContract}`, formattedContract],
    queryFn: () => fetchContractData(formattedContract),
    enabled: !!formattedContract,
    staleTime: 1000 * 60 * 5
  });

  // Buscar os dados do gráfico
  const fetchChartData = async (formattedContract, selectedTask) => {
    try {
      if (selectedTask) {
        const response = await api.get(
          `/braskem/chart-data?numContrato=${formattedContract}&taskNumber=${selectedTask.TaskOutlineNumber}`
        );
        return response.data;
      } else {
        const response = await api.get(`/braskem/chart-data?numContrato=${formattedContract}`);
        return response.data;
      }
    } catch (error) {
      throw new Error('Failed to fetch chart data');
    }
  };
  const chartDataQuery = useQuery({
    queryKey: [`braskem/chart-data/${formattedContract}/${selectedTask}`, formattedContract, selectedTask],
    queryFn: () => fetchChartData(formattedContract, selectedTask),
    enabled: !!formattedContract,
    staleTime: 1000 * 60 * 5
  });

  // Buscar os dados do cronograma
  const fetchTimelineData = async (formattedContract, selectedType) => {
    try {
      const response = await api.get(`/braskem/viewer-info?numContrato=${formattedContract}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch timeline data');
    }
  };
  const viewerTimelineSectionQuery = useQuery({
    queryKey: [`braskem/viewer-info/${formattedContract}`, formattedContract],
    queryFn: () => fetchTimelineData(formattedContract),
    enabled: !!formattedContract && selectedType === 'cronograma',
    staleTime: 1000 * 60 * 5
  });

  // Buscar os dados das tarefas
  const fetchTasksData = async (formattedContract) => {
    try {
        const response = await api.get('/braskem/tasks', {
          params: {
            numContrato: formattedContract
          }
      });

      // Lista de tarefas em formato de árvore para ser utilizado na tabela
      const taskTree = createTaskTree(
          response.data.map((item) => ({
            ...item,
            TaskOutlineLevel: Number(item.TaskOutlineLevel),
            AssignmentFinishDate: item.AssignmentFinishDate ? item.AssignmentFinishDate : null
          }))
      );

      // Retorna tanto a lista de tarefas em formato de árvore quanto a lista de tarefas normal
      return {
          taskTree,
          full: response.data.map((item) => ({
            ...item,
            TaskOutlineLevel: Number(item.TaskOutlineLevel),
            AssignmentFinishDate: item.AssignmentFinishDate ? item.AssignmentFinishDate : null
          }))
      };
    } catch (error) {
        throw new Error('Failed to fetch tasks data');
    }
    };
  const tasksQuery = useQuery({
    queryKey: [`braskem/tasks/${formattedContract}`, formattedContract],
    queryFn: () => fetchTasksData(formattedContract),
    enabled: !!formattedContract,
    staleTime: 1000 * 60 * 5
  });
  
  const isMainInfoQueryLoading =
    mainInfoQuery.isLoading && mainInfoQuery.fetchStatus === 'fetching';

  const isChartDataQueryLoading =
    chartDataQuery.isLoading && chartDataQuery.fetchStatus === 'fetching';

  // Atualiza as datas de término para ficar de acordo com a lógica (tarefas pais tem a data de term. igual a maior data de term. dos filhos)
  updateAssignmentFinishDates(tasksQuery.data?.taskTree);

  // Lista de tarefas selecionadas em formato de tabela (sem ser formato de árvore)
  const flattenedTasks = flattenTasks(selectedTask);

  useEffect(() => {
    if (contractsQueryData.data && contractsQueryData.data.length > 0) {
      setSelectedContract(contractsQueryData.data[0].objectId);
      handleSelectContract({ target: { value: contractsQueryData.data[0].objectId } });
    }
  }, [contractsQueryData.data]);

  function compare(a, b) {
    const partsA = a.TaskName.split('.').map(part => parseFloat(part));
    const partsB = b.TaskName.split('.').map(part => parseFloat(part));
  
    const maxLength = Math.max(partsA.length, partsB.length);
    
    for (let i = 0; i < maxLength; i++) {
      const partA = partsA[i] || 0;
      const partB = partsB[i] || 0;
  
      if (partA !== partB) {
        return partA - partB;
      }
    }
  
    return 0;
  }

  return (
    <div className="bg-braskemPrimary">
      <BraskemHeader menuOpen={mobileOpen} setMenuOpen={setMobileOpen}>
        <Select
          className="min-w-auto max-w-[150px]"
          label="Obra"
          onChange={handleSelectContract}
          value={selectedContract}>
          {contractsQueryData.data?.map((opcao) => (
            <MenuItem
              key={opcao?.objectId}
              label={opcao?.objectKey.slice(0, 8)}
              value={opcao?.objectId}>
              {opcao?.objectKey.slice(0, opcao.objectKey.length - 4)}
            </MenuItem>
          ))}
        </Select>
      </BraskemHeader>

      <div className="mt-16 p-4 space-y-4">
        <div className="grid text-white grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <MainCard
            label={contractState.formattedContract==="LDMM10_v01"?"Previsto Contratada": "Previsto"}
            content={`${mainInfoQuery.data?.previsto.toFixed(2) ?? 0}%`}
            isLoading={isMainInfoQueryLoading}
            tooltip="Trabalho planejado até hoje dividido pelo total planejado (linha de base)"
          />
          <MainCard
            label={contractState.formattedContract==="LDMM10_v01"?"Realizado Contratada": "Realizado"}
            content={`${mainInfoQuery.data?.realizado.toFixed(2) ?? 0}%`}
            isLoading={isMainInfoQueryLoading}
            tooltip="Trabalho realizado dividido pelo trabalho total"
          />
          <MainCard
            label={contractState.formattedContract==="LDMM10_v01"?"Desempenho Contratada": "Desempenho"}
            content={`${
              (mainInfoQuery.data?.realizado / mainInfoQuery.data?.previsto) * 100
                ? ((mainInfoQuery.data?.realizado / mainInfoQuery.data?.previsto) * 100).toFixed(2)
                : 0}%`}
            isLoading={isMainInfoQueryLoading}
            tooltip="Trabalho realizado dividido pelo previsto"
          />
          <MainCard
            label="Desvio Dias"
            content={mainInfoQuery.data?.diferenciaTendencia ?? 0}
            isLoading={isMainInfoQueryLoading}
            tooltip="Diferença em dias entre a data de finalização da linha de base e da projeção atual"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="border-none bg-braskemSecondary p-3 rounded">
            <div className="grid grid-cols-2 bg-braskemPrimary p-1 h-10 gap-1 rounded-md mb-2">
              <button
                className={cn(
                  'w-full text-center text-xs grid place-items-center text-white transition-colors rounded-md',
                  selectedType === 'avanco' ? 'bg-braskemSecondary' : 'bg-transparent hover:bg-braskemSecondary/60'
                )}
                onClick={() => setSelectedType('avanco')}>
                Avanço Físico
              </button>
              <button
                className={cn(
                  'w-full text-center text-xs grid place-items-center text-white transition-colors rounded-md',
                  selectedType === 'cronograma'
                    ? 'bg-braskemSecondary' : 'bg-transparent hover:bg-braskemSecondary/60'
                )}
                onClick={() => setSelectedType('cronograma')}>
                Cronograma
              </button>
            </div>

            <div className="h-[370px]">
              <ViewerBraskem
                selectedContract={selectedContract}
                defaultTasks={tasksQuery.data?.full}
                selectedTasks={flattenedTasks}
                selectedType={selectedType}
                extraData={viewerTimelineSectionQuery.data}
              />
            </div> 
            
            <div className="flex justify-center items-center gap-4 mt-3">
              {selectedType === 'avanco' && (
                <>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-cardVerde"></div>
                    <span className="text-sm text-cardVerde">Concluído</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-cardAmarelo"></div>
                    <span className="text-sm text-cardAmarelo">Em andamento</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-cardVermelho"></div>
                    <span className="text-sm text-cardVermelho">A iniciar</span>
                  </div>
                </>
              )}
              {selectedType === 'cronograma' && (
                <>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-cardVerde"></div>
                    <span className="text-sm text-cardVerde">Concluído</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-cardAmarelo"></div>
                    <span className="text-sm text-cardAmarelo">No prazo</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="w-4 h-4 bg-cardVermelho"></div>
                    <span className="text-sm text-cardVermelho">Em atraso</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={cn(
              'p-2 border bg-braskemSecondary border-none rounded min-h-[350px]',
              isChartDataQueryLoading && 'grid place-items-center'
            )}>
            {isChartDataQueryLoading ? (
              <Spinner className="text-white" />
            ) : (
              <BarLineChart
                previsto={chartDataQuery.data?.previsto ?? []}
                realizado={chartDataQuery.data?.realizado ?? []}
                projecao={chartDataQuery.data?.projecao ?? []}
                //selectedTask={selectedTask}
              />
            )}
          </div>
        </div>
        <div className="w-full rounded-md overflow-x-auto text-white flex flex-col gap-2 mt-2 bg-braskemSecondary">
          <div className="min-w-[900px] grid grid-cols-[400px_1fr_1fr_1fr_1fr_1fr_80px] gap-2 py-2 border-b border-slate-300 px-2">
            <span className="text-sm tex">Ação</span>
            <span className="text-sm tex">Previsto</span>
            <span className="text-sm tex">Realizado</span>
            <span className="text-sm tex">Término Est.</span>
            <span className="text-sm tex">Término Linha de Base</span>
            <span className="text-sm tex">Desvio (%)</span>
            <span className="text-sm tex">Desvio Dias</span>
          </div>
          {tasksQuery.isLoading && tasksQuery.fetchStatus === 'fetching' ? (
            <div className="h-24 w-full grid place-items-center">
              <Spinner />
            </div>
          ) : (
            <TaskTree
              tasks={tasksQuery.data?.taskTree.sort(compare) ?? []}
              openBranches={openBranches}
              onToggleBranch={toggleBranch}
              setSelectedTask={handleSelectTask}
              selectedTask={selectedTask}
              dynamicColor={'braskemSecondary'}
            />
          )}
        </div>
      </div>
    </div>
  );
}
