import React, { useEffect, useMemo, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { api } from '../../../lib/axios';

import { BraskemHeader } from '../BraskemHeader';
import iconMoeda from '../../../assets/icons/moeda.png';
import TabelaBms from '../../../Components/Tabelas/TableBMs';
import TabelaServicosSetor from '../../../Components/Tabelas/ServicosBMs';
import BarChart from '../../../Components/Chats/BarChart';
import OverviewTotalProfit from '../../../Components/Tasks/overview-total-profit';
import OverviewTasksProgress from '../../../Components/Tasks/overview-tasks-progress';
import Viewer from '../../../Components/PlugBase/Viewer/Viewer';

import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { default as MaterialUISelect } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../Components/Tabs';

// import { getDatesInRange } from '../../../utils/date';
// import VerticalBarChart from '../../../Components/Chats/VerticalBarChart';
// import { OverviewCard } from '../../../Components/OverviewCard';

//----------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#003C7F',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

function datesAreEqual(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

function MainFinanceiroBraskem() {
  //Recebendo a URN do modelo selecionado no dropDown de Contrato e em seguida o texto do elemento
  const [opcoesContrato, setOpcoesContrato] = useState([]);
  const [selectedOption, setSelectedOption] = useState(''); // contrato

  //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
  const [opcoesSetor, setOpcoesSetor] = useState([]);
  const [setorOption, setSetorOption] = useState([]);

  //Recebendo os setores referente ao modelo selecionado no dropDown de Contrato
  const [opcoesBMs, setOpcoesBMs] = useState([]);
  const [bmOption, setBmOption] = useState('');

  //Recebendo a data de vigencia referente ao modelo selecionado no dropDown de Contrato
  const [opcoesDataVigencia, setopcoesDataVigencia] = useState([]);

  //Recebendo os dados do BM agrupados para poder atualizar a tabela de BMS
  const [opcoesDataTableBms, setOpcoesDataTableBms] = useState([]);

  //Recebendo os dados do serviços agrupados para poder atualizar a tabela de serviços
  const [opcoesDataTableServicos, setOpcoesDataTableServicos] = useState([]);

  //Recebendo os dados de previsto x executado para atualizar graficos
  const [opcoesPrevistoXExecutado, setOpcoesPrevistoXExecutado] = useState([]);

  const [valoresPrevistoXExecutado, setvaloresPrevistoXExecutado] = useState([]);
  const [valorPrevistoAcumulado, setValorPrevistoAcumulado] = useState([]);
  
  const [setorComProgresso, setSetorComProgresso] = useState([]);

  //--------
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    api
      .get('/token/listObjectsPBB?instancia=braskem')
      .then((response) => {
        setOpcoesContrato(response.data.items);
        setSelectedOption(response.data.items[0].objectId);
        handleSelectChangeContract({ target: { value: response.data.items[0].objectId } });
      });
  }, []);

  const handleSelectChangeContract = (event) => {
    const urnValue = event.target.value;
    setSelectedOption(urnValue);

    api
      .get('braskem/dadossetor?numContrato=' + urnValue.split('/')[1] + '&instancia=braskem')
      .then((response) => setOpcoesSetor(response.data));

    api
      .get('braskem/dadosbm?numContrato=' + urnValue.split('/')[1] + '&instancia=braskem')
      .then((response) => { setOpcoesBMs(response.data)});

    api
      .get('braskem/dadosvigencia?numContrato=' + urnValue.split('/')[1] + '&instancia=braskem')
      .then((response) => { setopcoesDataVigencia(response.data)});

    api
      .get('braskem/dadoschartdata?numContrato=' + urnValue.split('/')[1] + '&instancia=braskem')
      .then((response) => setOpcoesPrevistoXExecutado(response.data));

    setMobileOpen(false);
  };

  const handleSelectChangeBM = (event) => {
    const bmValue = event.target.value;
    setBmOption(bmValue);
    setMobileOpen(false);
  };


  useEffect(() => {
    const urnValue = document.querySelector('#contratoIdSelect').innerText + '.nwd';

    setSetorOption(selectedOptions);

    api
      .get(
        'braskem/dadostabelabms?' +
          'numContrato=' +
          urnValue +
          '&setor=&bm=' +
          bmOption +
          '&instancia=braskem'
      )
      .then((response) => {
        setOpcoesDataTableBms(response.data);
        setvaloresPrevistoXExecutado(
          response.data.length > 0 ? [response.data[response.data.length - 1]] : response.data
        );
      });

    api
      .get(
        'braskem/valorprevistoacumulado?' +
          'numContrato=' +
          urnValue +
          '&setor=&bm=' +
          bmOption +
          '&instancia=braskem'
      )
      .then((response) => {
        setValorPrevistoAcumulado(response.data);
      });

    api
      .get(
        'braskem/dadostabelaservices?' +
          'numContrato=' +
          urnValue +
          '&setor=' +
          selectedOptions +
          '&instancia=braskem'
      )
      .then((response) => setOpcoesDataTableServicos(response.data));
  }, [selectedOptions, selectedOption, bmOption]);
  
  useEffect(() => {
    const urnValue = document.querySelector('#contratoIdSelect').innerText + '.nwd';

    setSetorComProgresso([]);
    let setoresSelecionados = selectedOptions; 

    if (selectedOptions.length === 0) {
      setoresSelecionados = opcoesSetor.map(objeto => objeto.Codigo_WBS);
    }
    //Cria uma array com as chamadas a serem feitas
    const promises = setoresSelecionados.map(codSetor =>
      api.get(`braskem/dadostabelabms2?numContrato=${urnValue}&setor=${codSetor}&bm=${bmOption}&instancia=braskem`)
    );
    Promise.all(promises)
      .then(responses => {
        const newSetores = responses.reduce((acc, response) => {
          if (response.data && response.data.length > 0) {
            const existsInArray = acc.findIndex(item => item.Codigo_WBS === response.data[0].Codigo_WBS);
            if (existsInArray === -1) {
              acc.push(response.data[0]);
            }
          }
          return acc;
        }, []);
        setSetorComProgresso(prevState => [...prevState, ...newSetores]);
      });
  }, [selectedOptions, bmOption, opcoesSetor]);

    // Tabela de Serviços que se adapta de acordo com o BM selecionado
    const opcoesTableServicosComBmSelecionado = useMemo(() => {
      if (!bmOption) {
        return [];
      }

      let data = opcoesDataTableServicos?.filter(
        (item) =>
          (item?.BMSP === bmOption)
      );
  
      return data;
    }, [opcoesDataTableServicos, bmOption, opcoesDataTableBms]);

  return (
    <div className="bg-braskemPrimary" style={{ padding: '15px', paddingTop: '1px'}}>
    <Box> 
      <BraskemHeader menuOpen={mobileOpen} setMenuOpen={setMobileOpen}>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="setorLabelID" style={{ color: '#fff' }}>
                Setor
              </InputLabel>
              <MaterialUISelect
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important'
                  }
                }}
                labelId="setorLabelID"
                id="setorIdSelect"
                label="Setor"
                multiple
                value={selectedOptions}
                onChange={(event) => setSelectedOptions(event.target.value)}
                renderValue={(selected) => selected.join(', ')}
                style={{ minWidth: '200px' }}>

                {opcoesSetor.map(option => {
                    const indent = String.fromCharCode(8194).repeat((option.Codigo_WBS.length - 7)/3);
                    const discriminacao = option.Discriminacao_WBS.length > 25 ? `${option.Discriminacao_WBS.slice(0, 25)}...` : option.Discriminacao_WBS;

                    return (
                      <MenuItem
                        key={uuidv4()}
                        value={option.Codigo_WBS}
                        style={{ display: 'flex', alignItems: 'center', padding: '4px 8px' }}
                      >
                        <Checkbox checked={selectedOptions.indexOf(option.Codigo_WBS) > -1} style={{ padding: '0 8px' }} />
                        <Tooltip title={option.Discriminacao_WBS}>
                          <ListItemText
                            primary={
                              <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                {`${indent}${discriminacao}`}
                              </span>
                            }
                            style={{ marginLeft: '8px' }}
                          />
                        </Tooltip>
                      </MenuItem>
                    );
                  })}
              </MaterialUISelect>
            </FormControl>

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="bmLabelID" style={{ color: '#fff' }}>
                BM
              </InputLabel>
              <MaterialUISelect
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important'
                  }
                }}
                labelId="bmLabelID"
                id="bmIdSelect"
                label="BM"
                onChange={handleSelectChangeBM}
                style={{ minWidth: '200px' }}
                value={bmOption}>
                <MenuItem key={uuidv4()} label={'Todos'} value={''}>
                  {'Todos'}
                </MenuItem>
                {(opcoesBMs.map((opcao) => (
                  <MenuItem key={opcao.BMSP} label={opcao.BMSP} value={opcao.BMSP}>
                    {opcao.BMSP}
                  </MenuItem>
                )))}
              </MaterialUISelect>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="contratoLabelID" style={{ color: '#fff' }}>
                Contrato
              </InputLabel>
              <MaterialUISelect
                sx={{
                  color: 'white',
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'rgba(228, 219, 233, 0.25)'
                  },
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important'
                  }
                }}
                labelId="contratoLabelID"
                id="contratoIdSelect"
                label="Contrato"
                onChange={handleSelectChangeContract}
                style={{ minWidth: '200px' }}
                value={selectedOption}>
                {opcoesContrato.map((opcao) => (
                  <MenuItem
                    key={uuidv4()}
                    label={opcao.objectKey.slice(0, 8)}
                    value={opcao.objectId}>
                    {opcao.objectKey.slice(0, opcao.objectKey.length - 4)}
                  </MenuItem>
                ))}
              </MaterialUISelect>
            </FormControl>
      </BraskemHeader>

      <Box mt="76px" display="flex" flexDirection="column" gap="16px">
        <Grid alignItems="stretch" container spacing={2}>
          <Grid sm={6} md={2.4} xs={12}>
            <OverviewTotalProfit
              sx={{ height: '100%' }}
              value={opcoesDataVigencia.map(
                (opcao) =>
                  `${new Date(opcao.Data_de_Inicio)
                    .toLocaleDateString('pt-BR')
                    .toString()} A ${new Date(opcao.Data_de_Termino_Atual_Vigencia)
                    .toLocaleDateString('pt-BR')
                    .toString()}`
              )}
              label="Prazo de Vigência"
              hexcolor="#003C7F"
              colorIcon="#62C17E"
              icon={iconMoeda}
            />
          </Grid>

          <Grid sm={6} md={2.4} xs={12}>
            <OverviewTotalProfit
              sx={{ height: '64%' }}
              value={
                `R$ ` +
                valoresPrevistoXExecutado.map((opcao) =>
                  new Intl.NumberFormat('pt-BR').format(opcao.ValorPrevisto_WBS)
                )
              }
              label="Valor Atual do Contrato"
              hexcolor="#003C7F"
              colorIcon="#62C17E"
              icon={iconMoeda}
            />
          </Grid>
          <Grid sm={6} md={2.4} xs={12}>
            <OverviewTotalProfit
              sx={{ height: '64%' }}
              value={
                `R$ ` +
                valorPrevistoAcumulado.map((opcao) =>
                  new Intl.NumberFormat('pt-BR').format(opcao.Total_Previsto_Ate_Atual)
                )
              }
              label="Valor Previsto Acumulado"
              hexcolor="#003C7F"
              colorIcon="#62C17E"
              icon={iconMoeda}
            />
          </Grid>

          <Grid sm={6} md={2.4} xs={12}>
            <OverviewTotalProfit
              sx={{ height: '64%' }}
              value={
                `R$ ` +
                valoresPrevistoXExecutado.map((opcao) =>
                  new Intl.NumberFormat('pt-BR').format(opcao.ValorAcumulado_WBS)
                )
              }
              label="Valor Medido Acumulado"
              hexcolor="#003C7F"
              colorIcon="#62C17E"
              icon={iconMoeda}
            />
            {/* <OverviewCard hexColor="#313862" label="Valor Executado" icon={iconMoeda} /> */}
          </Grid>
          <Grid sm={6} md={2.4} xs={12}>
            <OverviewTasksProgress
              sx={{ height: '100%' }}
              value={valoresPrevistoXExecutado}
              label={'Avanço Financeiro'}
              hexcolor="#003C7F"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid xs={12} md={7.5}>
            <Item className="flex flex-col gap-6 h-[480px] lg:h-full">
              <Viewer selectedContract={selectedOption} selectedSetores={setorComProgresso} />
              <div className="flex justify-center items-center gap-4 pb-1">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVerde"></div>
                  <span className="text-sm text-cardVerde">Concluído</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardAmarelo"></div>
                  <span className="text-sm text-cardAmarelo">Em andamento</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVermelho"></div>
                  <span className="text-sm text-cardVermelho">A iniciar</span>
                </div>
              </div>
            </Item>
          </Grid>

          <Grid xs={12} md={4.5}>
            <Item style={{ height: '65vh' }}>
              <BarChart 
                opcoesPrevistoXExecutado={opcoesPrevistoXExecutado}
                altura={'60vh'}
                cabecalho={'Previsto x Realizado - Execução Financeira'}
                modulo={'F'}
                eixo={'x'}
              />
            </Item>
          </Grid>
        </Grid>

        <Tabs defaultValue="bms">
          <TabsList color="braskem">
            <TabsTrigger color="braskem" value="bms">BMs</TabsTrigger>
            <TabsTrigger color="braskem" value="servicos">Serviços</TabsTrigger>
          </TabsList>
          <TabsContent value="bms">
            <Item>
              <TabelaBms altura={'300px'} opcoesDataTableBms={opcoesDataTableBms} />
            </Item>
          </TabsContent>
          <TabsContent value="servicos">
            <Item>
              <TabelaServicosSetor
                altura={'300px'}
                opcoesDataTableServicos={
                  bmOption ? opcoesTableServicosComBmSelecionado : opcoesDataTableServicos
                }
              />
            </Item>
          </TabsContent>
        </Tabs>

        {/* <Grid container spacing={2}>
          <Grid sm={12} md={6.3}>
            
          </Grid>

          <Grid sm={12} md={5.7}>
            
          </Grid>
        </Grid> */}
      </Box>
    </Box>
    </div>
  );
}

export { MainFinanceiroBraskem };
