import { forwardRef } from 'react';
import { Spinner } from '../../../Components/Spinner';

function Card({ title, value, isLoading }, ref) {
  return (
    <div
      className="bg-plugSecondary text-white rounded flex flex-col gap-2 items-center justify-center text-center p-3"
      ref={ref}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <span className="font-semibold">{title}</span>
          <strong className="text-4xl text-plugSpecial">{value}</strong>
        </>
      )}
    </div>
  );
}

export default forwardRef(Card);
